/**
 * Dividers
 */
.site-header:after {
// .page-content.hidden-header:before,
// .site-footer:before {
  background-size: auto 100%;
  text-align: center;
  content: "\00a0";
  color: $grey-color;
  display: block;
  height: 16px;
  margin: $spacing-unit*2 auto;
}

// .site-footer:before {
//   content: "\2501\2501\2501\2501\2501";
//   letter-spacing: -1px;
// }



/**
 * Site header
 */
 .brand-title {
  font-family: $brand-font-family;
  font-kerning: none;
  -webkit-font-feature-settings: "kern" 0;
     -moz-font-feature-settings: "kern" 0;
       -o-font-feature-settings: "kern" 0;
          font-feature-settings: "kern" 0;
  font-weight: 900 !important;
  font-size: 1rem !important;
  letter-spacing: 1px;
}

.site-header {
  position: relative;
  padding: 0;

  .wrapper {
    vertical-align: baseline;
  }
}

.site-title {
  font-weight: $bold-font-weight;
  line-height: $base-line-height * $base-font-size * 2.25;
  margin-bottom: 0;
  float: left;

  &,
  &:visited {
    color: $text-color;
  }
}

.site-nav {
  float: right;
  line-height: $base-line-height * $base-font-size * 2.25;

  .nav-trigger {
      display: none;
  }

  .menu-icon {
    display: none;
  }

  .page-link {
    color: $text-color;
    line-height: $base-line-height;

    &:not(:last-child) {
      margin-right: 20px;
    }
  }

  @include media-query($on-palm) {
    position: absolute;
    top: 9px;
    right: $spacing-unit / 2;
    background-color: $background-color;
    border: 1px solid $grey-color-light;
    border-radius: 5px;
    text-align: right;

    label[for="nav-trigger"] {
      display: block;
      float: right;
      width: 36px;
      height: 36px;
      z-index: 2;
      cursor: pointer;
    }

    .menu-icon {
      display: block;
      float: right;
      width: 36px;
      height: 26px;
      line-height: 0;
      padding-top: 10px;
      text-align: center;

      > svg {
        fill: $grey-color-dark;
      }
    }

    input ~ .trigger {
      clear: both;
      display: none;
    }

    input:checked ~ .trigger {
      display: block;
      padding-bottom: 5px;
    }

    .page-link {
      display: block;
      padding: 5px 10px;

      &:not(:last-child) {
        margin-right: 0;
      }
      margin-left: 20px;
    }
  }
}



/**
 * Site footer
 */
.site-footer {
  padding: 0 0 $spacing-unit/2;
}

.footer-heading {
  @include relative-font-size(1.125);
  margin-bottom: $spacing-unit / 2;
}

.contact-list,
.footer-menu-list,
.social-media-list {
  list-style: none;
  margin-left: 0;

  li {
    display: inline-block;
  }

  li + li {
    margin-left: $spacing-unit;
  }
}

.footer-col-wrapper {
  @include relative-font-size(0.9375);
  color: $grey-color;
  margin-left: -$spacing-unit / 2;
  @extend %clearfix;
}

.footer-col {
  float: left;
  margin-bottom: $spacing-unit / 2;
  padding-left: $spacing-unit / 2;
}

.footer-col-1 {
  text-align: center;
  width: -webkit-calc(100% - (#{$spacing-unit} / 2));
  width:         calc(100% - (#{$spacing-unit} / 2));
}

.footer-col-2 {
  width: -webkit-calc(65% - (#{$spacing-unit} / 2));
  width:         calc(65% - (#{$spacing-unit} / 2));
}

@include media-query($on-palm) {
  .footer-col {
    float: none;
    width: -webkit-calc(100% - (#{$spacing-unit} / 2));
    width:         calc(100% - (#{$spacing-unit} / 2));
  }
}



/**
 * Page content
 */
.brand-summary {
  max-width: 570px;
  font-size: 1.5rem;
  line-height: 1.7;
  font-weight: 300;

  h1 {
    font-size: inherit !important;
    display:inline;
  }

  em {
    font-feature-settings: "salt";
  }

  a {
    font-weight: 400;
    color: $text-color;
    border-bottom: 3px solid $text-color;

    &:hover {
      color: $link-color;
      border-bottom: 3px solid $background-color;
      text-decoration: none;
    }
  }
}

.page-content {
  padding: 0;
  flex: 1;
}

.page-heading {
  @include relative-font-size(2);
}

.post-list {
  margin-left: 0;
  list-style: none;

  > li {
    display: flex;
    align-items: baseline;
    flex-direction: row;

    > h3 {
      display: inline-block;
      font-weight: $base-font-weight;
      margin-bottom: 0;
    }
  }
}

.post-list-heading-archive {
  @include relative-font-size(1.25);
  margin: $spacing-unit*1.5 0 $spacing-unit/2;

  &:first-child {
    margin-top: 0;
  }
}

.post-meta {
  font-size: $small-font-size;
  color: $grey-color;
  margin-right: $spacing-unit / 2;

  .post-list & {
    display: inline-block;
    width: 95px;
    flex-shrink: 0;
    text-align: right;
  }
}

.post-link {
  font-size: $base-font-size;
}



/**
 * Posts
 */
.post-header {
  margin-bottom: $spacing-unit;
}

.post-title {
  @include relative-font-size(2);
  line-height: 1;
  font-weight: $black-font-weight;

  @include media-query($on-laptop) {
    @include relative-font-size(2.25);
  }
}

.post-content {
  margin-bottom: $spacing-unit;

  h1, h2, h3, h4, h5, h6 {
    margin-top: $spacing-unit*1.5;
  }

  h2 {
    @include relative-font-size(2);
    font-weight: 300;
  }

  h3 {
    @include relative-font-size(1.5);
    font-weight: 500;
  }

  h4 {
    @include relative-font-size(1.1);
    font-weight: 700;
  }

  table,
  img,
  .highlight {
    margin: $spacing-unit*1.5 auto;
  }

  .d3graphics {
    overflow: scroll;
    font-size: .8rem;
    text-align: center;
    margin: $spacing-unit*2 0;

    text {
      fill: #444;
    }

    .bar {
      stroke: #bbb;
    }
  
    .axis path,
    .axis line {
      fill: none;
      stroke: #444;
      shape-rendering: crispEdges;
    }
  
    .y.axis text {
      text-anchor: start !important;
    }
  
    .x.axis text {
      font-size: .7rem;
    }
    
    line.total {
      stroke: #444;
      stroke-width: 1;
      shape-rendering: crispEdges;
    }
  }
}



/**
 * Hero
 */
.hero {
  box-sizing: border-box;
  width: 100vw;
  padding: $spacing-unit/2;
  display: grid;
  align-items: center;
  justify-items: center;
}



/**
 * Utils
 */
.height-100 {
  height: 100vh;
}
.height-75 {
 min-height: 75vh;
}
.height-66 {
 height: 66vh;
}
.height-50 {
  height: 50vh;
}
.height-33 {
  height: 33vh;
}

.text-center {
  text-align: center;
}
