/**
 * Home
 */
 #home {
  text-align: center;

  h1 {
    @include relative-font-size(1.5);
    font-weight: $base-font-weight;
  }

  p {
    @include relative-font-size(1.5);
    font-weight: $black-font-weight;
    font-style: italic;
  }
}

/**
 * 404
 */
#page404 {
  text-align: center;
}